import React from "react"
import PropTypes from "prop-types"

const Building = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <rect id="bound" width="20" height="20" fill="none" />
      <path
        id="Combined-Shape"
        d="M12.083,17.833v-2.5a.833.833,0,0,0-.833-.833h-.833a.833.833,0,0,0-.833.833v2.5H5V3.667A1.667,1.667,0,0,1,6.667,2H15a1.667,1.667,0,0,1,1.667,1.667V17.833ZM8.333,3.667A.833.833,0,0,0,7.5,4.5v.833a.833.833,0,0,0,.833.833h.833A.833.833,0,0,0,10,5.333V4.5a.833.833,0,0,0-.833-.833Zm4.167,0a.833.833,0,0,0-.833.833v.833a.833.833,0,0,0,.833.833h.833a.833.833,0,0,0,.833-.833V4.5a.833.833,0,0,0-.833-.833ZM8.333,7a.833.833,0,0,0-.833.833v.833a.833.833,0,0,0,.833.833h.833A.833.833,0,0,0,10,8.667V7.833A.833.833,0,0,0,9.167,7Zm0,3.333a.833.833,0,0,0-.833.833V12a.833.833,0,0,0,.833.833h.833A.833.833,0,0,0,10,12v-.833a.833.833,0,0,0-.833-.833Zm4.167,0a.833.833,0,0,0-.833.833V12a.833.833,0,0,0,.833.833h.833A.833.833,0,0,0,14.167,12v-.833a.833.833,0,0,0-.833-.833Z"
        transform="translate(-0.833 -0.333)"
        fill={color}
        fillRule="evenodd"
      />
      <rect
        id="Rectangle-Copy-2"
        width="2.5"
        height="2.5"
        rx="1"
        transform="translate(10.833 6.667)"
        fill={color}
      />
      <path
        id="Rectangle-2"
        d="M3.833,21H17.167a.833.833,0,0,1,.833.833v.333a.5.5,0,0,1-.5.5H3.5a.5.5,0,0,1-.5-.5v-.333A.833.833,0,0,1,3.833,21Z"
        transform="translate(-0.5 -3.5)"
        fill={color}
        fillRule="evenodd"
        opacity="0.3"
      />
    </svg>
  )
}

Building.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Building.defaultProps = {
  color: "#FFFFFF",
  width: 20,
  height: 20,
}

export default Building
